exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brand-assets-js": () => import("./../../../src/pages/brand-assets.js" /* webpackChunkName: "component---src-pages-brand-assets-js" */),
  "component---src-pages-brand-page-brand-page-js": () => import("./../../../src/pages/BrandPage/BrandPage.js" /* webpackChunkName: "component---src-pages-brand-page-brand-page-js" */),
  "component---src-pages-brand-page-colors-js": () => import("./../../../src/pages/BrandPage/Colors.js" /* webpackChunkName: "component---src-pages-brand-page-colors-js" */),
  "component---src-pages-brand-page-fonts-js": () => import("./../../../src/pages/BrandPage/Fonts.js" /* webpackChunkName: "component---src-pages-brand-page-fonts-js" */),
  "component---src-pages-brand-page-logos-js": () => import("./../../../src/pages/BrandPage/Logos.js" /* webpackChunkName: "component---src-pages-brand-page-logos-js" */),
  "component---src-pages-common-footer-js": () => import("./../../../src/pages/Common/Footer.js" /* webpackChunkName: "component---src-pages-common-footer-js" */),
  "component---src-pages-common-layout-js": () => import("./../../../src/pages/Common/Layout.js" /* webpackChunkName: "component---src-pages-common-layout-js" */),
  "component---src-pages-common-navbar-js": () => import("./../../../src/pages/Common/Navbar.js" /* webpackChunkName: "component---src-pages-common-navbar-js" */),
  "component---src-pages-home-page-audited-section-js": () => import("./../../../src/pages/HomePage/AuditedSection.js" /* webpackChunkName: "component---src-pages-home-page-audited-section-js" */),
  "component---src-pages-home-page-blue-chip-section-js": () => import("./../../../src/pages/HomePage/BlueChipSection.js" /* webpackChunkName: "component---src-pages-home-page-blue-chip-section-js" */),
  "component---src-pages-home-page-borrow-section-js": () => import("./../../../src/pages/HomePage/BorrowSection.js" /* webpackChunkName: "component---src-pages-home-page-borrow-section-js" */),
  "component---src-pages-home-page-coming-soon-js": () => import("./../../../src/pages/HomePage/ComingSoon.js" /* webpackChunkName: "component---src-pages-home-page-coming-soon-js" */),
  "component---src-pages-home-page-composable-section-js": () => import("./../../../src/pages/HomePage/ComposableSection.js" /* webpackChunkName: "component---src-pages-home-page-composable-section-js" */),
  "component---src-pages-home-page-earn-more-section-js": () => import("./../../../src/pages/HomePage/EarnMoreSection.js" /* webpackChunkName: "component---src-pages-home-page-earn-more-section-js" */),
  "component---src-pages-home-page-future-section-js": () => import("./../../../src/pages/HomePage/FutureSection.js" /* webpackChunkName: "component---src-pages-home-page-future-section-js" */),
  "component---src-pages-home-page-get-started-section-js": () => import("./../../../src/pages/HomePage/GetStartedSection.js" /* webpackChunkName: "component---src-pages-home-page-get-started-section-js" */),
  "component---src-pages-home-page-home-page-js": () => import("./../../../src/pages/HomePage/HomePage.js" /* webpackChunkName: "component---src-pages-home-page-home-page-js" */),
  "component---src-pages-home-page-leveraged-farming-section-js": () => import("./../../../src/pages/HomePage/LeveragedFarmingSection.js" /* webpackChunkName: "component---src-pages-home-page-leveraged-farming-section-js" */),
  "component---src-pages-home-page-low-fee-section-js": () => import("./../../../src/pages/HomePage/LowFeeSection.js" /* webpackChunkName: "component---src-pages-home-page-low-fee-section-js" */),
  "component---src-pages-home-page-mobile-section-js": () => import("./../../../src/pages/HomePage/MobileSection.js" /* webpackChunkName: "component---src-pages-home-page-mobile-section-js" */),
  "component---src-pages-home-page-more-assets-section-js": () => import("./../../../src/pages/HomePage/MoreAssetsSection.js" /* webpackChunkName: "component---src-pages-home-page-more-assets-section-js" */),
  "component---src-pages-home-page-partners-investors-integrations-section-js": () => import("./../../../src/pages/HomePage/PartnersInvestorsIntegrationsSection.js" /* webpackChunkName: "component---src-pages-home-page-partners-investors-integrations-section-js" */),
  "component---src-pages-home-page-secondary-title-section-js": () => import("./../../../src/pages/HomePage/SecondaryTitleSection.js" /* webpackChunkName: "component---src-pages-home-page-secondary-title-section-js" */),
  "component---src-pages-home-page-title-section-js": () => import("./../../../src/pages/HomePage/TitleSection.js" /* webpackChunkName: "component---src-pages-home-page-title-section-js" */),
  "component---src-pages-home-page-whitepaper-section-js": () => import("./../../../src/pages/HomePage/WhitepaperSection.js" /* webpackChunkName: "component---src-pages-home-page-whitepaper-section-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quick-start-desktop-mdx": () => import("./../../../src/pages/QuickStart/Desktop.mdx" /* webpackChunkName: "component---src-pages-quick-start-desktop-mdx" */),
  "component---src-pages-quick-start-guide-js": () => import("./../../../src/pages/QuickStart/guide.js" /* webpackChunkName: "component---src-pages-quick-start-guide-js" */),
  "component---src-pages-quick-start-index-js": () => import("./../../../src/pages/QuickStart/index.js" /* webpackChunkName: "component---src-pages-quick-start-index-js" */),
  "component---src-pages-quick-start-mobile-mdx": () => import("./../../../src/pages/QuickStart/Mobile.mdx" /* webpackChunkName: "component---src-pages-quick-start-mobile-mdx" */),
  "component---src-pages-verification-complete-js": () => import("./../../../src/pages/verification-complete.js" /* webpackChunkName: "component---src-pages-verification-complete-js" */),
  "component---src-pages-verification-verification-complete-page-js": () => import("./../../../src/pages/Verification/VerificationCompletePage.js" /* webpackChunkName: "component---src-pages-verification-verification-complete-page-js" */)
}

